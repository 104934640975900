exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-frontpage-js": () => import("./../../../src/pages/en/frontpage.js" /* webpackChunkName: "component---src-pages-en-frontpage-js" */),
  "component---src-pages-en-rooms-and-images-js": () => import("./../../../src/pages/en/rooms-and-images.js" /* webpackChunkName: "component---src-pages-en-rooms-and-images-js" */),
  "component---src-pages-fi-huoneet-ja-kuvat-js": () => import("./../../../src/pages/fi/huoneet-ja-kuvat.js" /* webpackChunkName: "component---src-pages-fi-huoneet-ja-kuvat-js" */),
  "component---src-pages-fi-yhteystiedot-js": () => import("./../../../src/pages/fi/yhteystiedot.js" /* webpackChunkName: "component---src-pages-fi-yhteystiedot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

